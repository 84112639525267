import { getDeviceList_returns_data_item } from "../getDeviceList";

export const HardCodedDevices: getDeviceList_returns_data_item[] = [
  {
    lat: "22.1235430",
    location: "Sundarban Bijoynagar Village",
    long: "88.7752330",
    dev_id: "4146272", // "b433e6aa0de9bef1bcff3ab6abc8f0cb",
    loc_id: 296,
    district: "South 24 Pgs.",
    aqi: "0",
  },

  {
    lat: "23.2266683",
    location: "Bankura Purandihi Village",
    long: "86.9695283",
    dev_id: "4146488", // "b433e6aa0de9bef1bcff3ab6abc8f0cb",
    loc_id: 298,
    district: "Bankura",
    aqi: "0",
  },
  {
    lat: "23.4309717",
    location: "Purulia Bheladi Village",
    long: "86.3127717",
    dev_id: "4175160", // "b433e6aa0de9bef1bcff3ab6abc8f0cb",
    loc_id: 9,
    district: "Purulia",
    aqi: "0",
  },
  {
    lat: "22.4228867",
    location: "Tiyakathi Village",
    long: "87.0310783",
    dev_id: "4145544", // "b433e6aa0de9bef1bcff3ab6abc8f0cb",
    loc_id: 311,
    district: "Jhargram",
    aqi: "0",
  },
  // {
  //   lat: "22.869474",
  //   location: "Himadri Speciality Chemical Limited, Singur Plant 2", // Himadri AQMS 2
  //   long: "88.21579",
  //   dev_id: "96dd806421b67a86c500c7aa45ef43db", // "0b44f0afd06696ad337a8d5338f1dcdc",
  //   loc_id: 114,
  //   district: "Hooghly",
  //   aqi: "0",
  // },
  // {
  //   lat: "22.871353", //VOC
  //   location: "Himadri Chemicals, Singur Plant",
  //   long: "88.211655",
  //   dev_id: "ab42c3fb27d7ee0ba9832ff23a079d5b",
  //   loc_id: 0,
  //   district: "Hooghly",
  //   aqi: "0",
  // },
];
